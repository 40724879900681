(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("null"), (function webpackLoadOptionalExternalModule() { try { return require("esprima"); } catch(e) {} }()));
	else if(typeof define === 'function' && define.amd)
		define(["null", "esprima"], factory);
	else if(typeof exports === 'object')
		exports["Redoc"] = factory(require("null"), (function webpackLoadOptionalExternalModule() { try { return require("esprima"); } catch(e) {} }()));
	else
		root["Redoc"] = factory(root["null"], root["esprima"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__62__, __WEBPACK_EXTERNAL_MODULE__265__) {
return 